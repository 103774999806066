
import OnboardRoutes from './onboard-route';
import AuthRoutes from './auth-route';

const Navigation = () => {
    const isAuthorized = false
    return (
        <>
            {
                isAuthorized ?
                    // <AuthRoutes />
                    <OnboardRoutes />
                    :
                    <OnboardRoutes />
            }
        </>
    )
}


export default Navigation;