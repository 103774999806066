import OrderHistory from "../pages/order-history";

export const routerPath = {
    home: '/home',
    about: '/about',
    contect: '/contect',
    offer: '/offer',
    claimOffer: '/calim-offer',
    contectUs: '/contect-us',
    faqs: '/faqs',
    profile: '/profile',
    OrderHistory: '/order-history',


}