import React from 'react'
import { IMAGES } from '../../../assets/images'

const Accordion = (props) => {
    return (
        <>
            <div className='flex bg-white border-b border-[#92929a] p-3'>
                <div className='w-[10%]'>
                    <p className='text-[#92929a] font-bold text-center text-4xl'>
                        {props.id}
                    </p>
                </div>
                <div className='w-[80%]'>
                    <p className='font-bold text-xl'>
                        {props.question}
                    </p>
                    {
                        props.is_open &&
                        <p className='text-md'>
                            {props.answer}
                        </p>
                    }
                </div>
                <div className='w-[10%] text-center'>
                    {
                        props.is_open ?
                            <img src={IMAGES.xmark} alt="" className='m-auto' onClick={props.click} />
                            :
                            <img src={IMAGES.plus} alt="" className='m-auto' onClick={props.click} />
                    }
                </div>
            </div>
        </>
    )
}

export default Accordion