import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { routerPath } from '../../navigation/path';
import { IMAGES } from '../../../assets/images';
import Sidebar from '../../components/sidebar/sidebar';

const Header = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate()
    const [isUserLogin, setUserLogin] = useState(false)

    useEffect(() => {
        const userLogin = JSON.parse(localStorage.getItem('is_user_login')) || false;
        setUserLogin(userLogin)
    })

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const sendToMenu = (menu) => {
        menu == 'home' && navigate(routerPath.home)
        menu == 'about' && navigate(routerPath.about)
        menu == 'offer' && navigate(routerPath.offer)
        menu == 'orderHistory' && navigate(routerPath.OrderHistory)
        menu == 'profile' && navigate(routerPath.profile)

    }
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    return (
        <>
            <MobileMenu isOpen={isSidebarOpen} onClose={toggleSidebar} />
            <header className=" p-4 bg-[#0673EA]">
                <div className="container mx-auto flex justify-between items-center">
                    <div className="text-white text-xl font-bold">
                        <img src={IMAGES.bigcityLogo} alt="" className='w-50' />
                    </div>
                    <div className="block lg:hidden">
                        <button
                            onClick={toggleSidebar}
                            className="text-white focus:outline-none focus:text-white"
                        >
                            <svg
                                className="h-6 w-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                ></path>
                            </svg>
                        </button>
                    </div>

                    {/* Desktop Menu */}
                    <div className="hidden lg:flex space-x-4 ">
                        <ul className='flex mb-0'>
                            <li className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('home')}>Home </li>
                            <li className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('about')}>About Us</li>
                            <li className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('offer')}>Offers</li>
                            <li className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('services')}>Privileges</li>
                            {
                                isUserLogin &&
                                <li className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('orderHistory')}>Order History</li>
                            }
                            {
                                isUserLogin &&
                                <li className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('profile')}>Profile</li>
                            }
                        </ul>

                    </div>

                    {/* Mobile Menu */}
                    {isMenuOpen && (
                        <div className="lg:hidden absolute top-0 left-0 w-full h-screen bg-[#0673EA]">
                            <div className="flex flex-col  pt-8 space-y-4">
                                <div className='text-end pr-5'>
                                    <button
                                        onClick={toggleMenu}
                                        className="text-white focus:outline-none focus:text-white"
                                    >
                                        <svg
                                            className="h-6 w-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M4 6h16M4 12h16m-7 6h7"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>

                                <ul >
                                    <li className='text-white justify-between items-left pt-3 flex' onClick={() => sendToMenu('home')}>Home</li>
                                    <li className='text-white justify-between items-left flex pt-3' onClick={() => sendToMenu('about')}>About Us</li>
                                    <li className='text-white justify-between items-left flex pt-3' onClick={() => sendToMenu('offer')}>Offers</li>
                                    <li className='text-white justify-between items-left flex pt-3' onClick={() => sendToMenu('services')}>Privileges</li>
                                    {
                                        isUserLogin &&
                                        <li className='text-white justify-between items-left flex pt-3' onClick={() => sendToMenu('orderHistory')}>Order History</li>
                                    }
                                    {
                                        isUserLogin &&
                                        <li className='text-white justify-between items-left flex pt-3' onClick={() => sendToMenu('profile')}>Profile</li>
                                    }

                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </header>
        </>


    )
}

export default Header


const MobileMenu = ({ isOpen, onClose }) => {
    const navigate = useNavigate()
    const sendToMenu = (menu) => {
        menu == 'home' && navigate(routerPath.home)
        menu == 'about' && navigate(routerPath.about)
        menu == 'offer' && navigate(routerPath.offer)
        menu == 'orderHistory' && navigate(routerPath.OrderHistory)
        menu == 'profile' && navigate(routerPath.profile)
    }
    const [isUserLogin, setUserLogin] = useState(false)
    useEffect(() => {
        const userLogin = JSON.parse(localStorage.getItem('is_user_login')) || false;
        setUserLogin(userLogin)
    })
    return (

        <div className={`fixed w-[85vw] inset-y-0 left-0 bg-[#0673EA] z-50 transform transition duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="flex items-center justify-between p-4">
                {/* <h1 className="text-black text-lg font-semibold">Product categories</h1> */}
                <img src={IMAGES.bigcityLogo} alt="" className='w-[60px]' />
                <button onClick={onClose} className="text-white focus:outline-none">
                    <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M12.3 10l5.4-5.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-5.4 5.4-5.4-5.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.4 5.4-5.4 5.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l5.4-5.4 5.4 5.4c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-5.4-5.4z"></path>
                    </svg>
                </button>
            </div>
            <div className="overflow-y-auto h-full">
                {/* Sidebar content */}
                <div className='px-4 mt-5'>
                    {/* <p className="font-bold text-md">
                        Offers
                    </p> */}
                    <div className="ml-4 pb-5">
                        <p className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('home')}>Home </p>
                        <p className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('about')}>About Us</p>
                        <p className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('offer')}>Offers</p>
                        <p className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('services')}>Privileges</p>
                        {
                            isUserLogin &&
                            <p className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('orderHistory')}>Order History</p>
                        }
                        {
                            isUserLogin &&
                            <p className='text-white justify-between items-center w-32 font-bold' onClick={() => sendToMenu('profile')}>Profile</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};