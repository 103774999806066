import axios from 'axios';
import { baseUrl } from '../http/api-configuration';

let token = localStorage.getItem('auth_token');

export const axiosInstance = axios.create({
    baseURL: baseUrl, // Corrected assignment of baseURL
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''  // Simplified authorization header assignment
    }
});

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            localStorage.clear(); // Clear localStorage on unauthorized response
            // Redirect to login or handle unauthorized case as per your application logic
            console.log('Unauthorized');
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
