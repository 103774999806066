
import React, { createContext, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { routerPath } from '../../navigation/path';
import { useNavigate } from 'react-router-dom';

export const AuthorizationContext = createContext();


export const AuthorizationProvider = ({ children }) => {
    const [isAuthorized, setIsAuthorized] = useState(localStorage.getItem('auth_token') ? true : false);

    const login = () => {
        // Perform login logic and set isAuthorized to true if successful
        setIsAuthorized(true);
    };

    const logout = () => {
        // Perform logout logic and set isAuthorized to false
        setIsAuthorized(false);
        localStorage.clear()


        // navigate(routerPath.mobile, { replace: true });
    };

    return (
        <AuthorizationContext.Provider value={{ isAuthorized, login, logout }}>
            {children}
        </AuthorizationContext.Provider>
    );
};