import React from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import { useEffect } from 'react'
import ApiService from '../../services/http/api-service'
import { ApiConfiguration } from '../../services/http/api-configuration'

const About = () => {
  useEffect(() => {
    getDashboardData()
  }, [])

  const getDashboardData = () => {
    console.log("Dads")
    ApiService.get(`http://127.0.0.1:3000/v1/customer/dashboard`).then(res => {
      console.log(res)
    }).catch(err => {

    })
  }
  return (
    <section >
      <div className='bg-white'>
        <div className='h-full'>
          <Header />
          <div className='grid grid-cols-1 bg-white container py-5'>
            <div>
              <h4 className='font-bold mb-3'>
                About Us
              </h4>
              <p>
                BizPrime is a membership-driven, revolutionary program for SMEs like yours. From accounting & finance, to travel & utilities, to HR & Payroll, Logistics, and much more! BizPrime is a breakthrough in a domain where SMEs are venturing out and are in need of multiple services. No more talking to numerous vendors or managing multiple accounts for your everyday business.
                BizPrime’s motto at the very core is to help your business SAVE – up to ₹99,999+ annually. And how are we enabling that? As a BizPrime member you gain exclusive access to 100+ offers from partners like DHL, ClearTax, SignEasy and OYO along with special memberships across services like Payroll, Logistics, Finance and more. With this, you will be saving on every service and every membership your business needs. In addition to this, we bring you a spectrum of lifestyle benefits like EazyDiner, MakeMyTrip and Cult.fit to help you grow personally.
                BizPrime is brought to you by BigCity Promotions’ 15 years of industry experience in delivering cutting edge Marketing and Promotional Solutions for some of the biggest brands in the country. This experience has enabled BizPrime’s model of empowering small and medium sized business owners unlock value in terms of learning, saving, networking and more.
                BizPrime is here to help you grow your business to its maximum potential. Get started today
              </p>
              <p>
                BizPrime is a membership-driven, revolutionary program for SMEs like yours. From accounting & finance, to travel & utilities, to HR & Payroll, Logistics, and much more! BizPrime is a breakthrough in a domain where SMEs are venturing out and are in need of multiple services. No more talking to numerous vendors or managing multiple accounts for your everyday business.
                BizPrime’s motto at the very core is to help your business SAVE – up to ₹99,999+ annually. And how are we enabling that? As a BizPrime member you gain exclusive access to 100+ offers from partners like DHL, ClearTax, SignEasy and OYO along with special memberships across services like Payroll, Logistics, Finance and more. With this, you will be saving on every service and every membership your business needs. In addition to this, we bring you a spectrum of lifestyle benefits like EazyDiner, MakeMyTrip and Cult.fit to help you grow personally.
                BizPrime is brought to you by BigCity Promotions’ 15 years of industry experience in delivering cutting edge Marketing and Promotional Solutions for some of the biggest brands in the country. This experience has enabled BizPrime’s model of empowering small and medium sized business owners unlock value in terms of learning, saving, networking and more.
                BizPrime is here to help you grow your business to its maximum potential. Get started today
              </p>
              <p>
                BizPrime is a membership-driven, revolutionary program for SMEs like yours. From accounting & finance, to travel & utilities, to HR & Payroll, Logistics, and much more! BizPrime is a breakthrough in a domain where SMEs are venturing out and are in need of multiple services. No more talking to numerous vendors or managing multiple accounts for your everyday business.
                BizPrime’s motto at the very core is to help your business SAVE – up to ₹99,999+ annually. And how are we enabling that? As a BizPrime member you gain exclusive access to 100+ offers from partners like DHL, ClearTax, SignEasy and OYO along with special memberships across services like Payroll, Logistics, Finance and more. With this, you will be saving on every service and every membership your business needs. In addition to this, we bring you a spectrum of lifestyle benefits like EazyDiner, MakeMyTrip and Cult.fit to help you grow personally.
                BizPrime is brought to you by BigCity Promotions’ 15 years of industry experience in delivering cutting edge Marketing and Promotional Solutions for some of the biggest brands in the country. This experience has enabled BizPrime’s model of empowering small and medium sized business owners unlock value in terms of learning, saving, networking and more.
                BizPrime is here to help you grow your business to its maximum potential. Get started today
              </p>
            </div>

          </div>
          <Footer />

        </div>
      </div>
      {/* <Footer /> */}

    </section>
  )
}

export default About