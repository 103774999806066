import Card from "../../components/card/card"
import Footer from "../footer/footer"
import Header from "../header/header"
import { IMAGES } from "../../../assets/images"
import { useNavigate, useSearchParams } from "react-router-dom"
import { routerPath } from "../../navigation/path"
import { useEffect, useState } from "react"
import Sidebar from "../../components/sidebar/sidebar"
import ApiService from "../../services/http/api-service"
import { ApiConfiguration } from "../../services/http/api-configuration"

const Offer = () => {
    const navigate = useNavigate()
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const [categoriyId, setCategoriyId] = useState(searchParams.get('category_id'))
    const [categories, setCategories] = useState([])
    const [product, setProduct] = useState([])


    useEffect(() => {
        getProductByCat()
        getCategories()
    }, [])

    const getProductByCat = () => {
        ApiService.get(ApiConfiguration.getProductByCat + `?id=${categoriyId == null ? '' : categoriyId}`).then((res) => {
            console.log(res.data.data)
            setProduct(res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const getCategories = () => {
        ApiService.get(ApiConfiguration.categories).then((res) => {
            console.log(res.data.data)
            setCategories(res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }


    const cardClick = () => {
        navigate(routerPath.claimOffer)
    }
    const categoryClick = (data) => {
        console.log(data)
        setCategoriyId(data.id)
        // categoriyId = data.id
        setTimeout(() => {
            getProductByCat()
        })
    }

    const productClick = (data) => {
        navigate(`${routerPath.claimOffer}?offer_id=${data.id}`)
    }


    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    return (
        <>
            <div className="bg-[#f1f1f1]">
                <Header />

                <div className="container">
                    <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} data={categories} />
                    <div className="text-black px-4 pt-4 block sm:hidden">
                        <button onClick={toggleSidebar} className="focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                            </svg>
                        </button>
                    </div>
                    <div className="grid grid-cols-4 gap-5">
                        <div className="col-span-1 py-5 lg:block hidden">
                            <div className=" flex bg-white mb-3">
                                <input type="text" className="w-[90%] p-2 text-black" placeholder="Search" />
                                <img src={IMAGES.search} alt="" />
                            </div>
                            <div className="bg-white p-3">
                                <h6 className="font-bold border-b border-[#f1f1f1] py-2">
                                    Product categories
                                </h6>
                                <div>
                                    <p className="font-bold text-sm">
                                        Offers
                                    </p>
                                    <div className="ml-4">
                                        {
                                            categories &&
                                            categories.map((e) =>
                                                <p className='text-sm text-[#757575]' key={e.id} onClick={() => categoryClick(e)}>{e.name} </p>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-span-4 lg:col-span-3">
                            <div>
                                <img src="" alt="" />
                            </div>
                            {
                                product.offer &&
                                <div>
                                    {/* <h4>
                                        Offers
                                    </h4> */}
                                    <div className="grid lg:grid-cols-3 grid-cols-1 sm:py-5 py-5">
                                        {

                                            product.offer.map((e, i) =>
                                                <div className="px-2 overflow-hidden" key={e.id}>
                                                    <Card cardType='Heading' img={e.image1} title={e.name} click={() => productClick(e)} />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                            {
                                product.privileges &&
                                <div>
                                    {/* <h4>
                                        Privilleges
                                    </h4> */}
                                    <div className="grid lg:grid-cols-3 grid-cols-1 sm:py-5 py-5">
                                        {

                                            product.privileges.map((e, i) =>
                                                <div className="px-2 overflow-hidden" key={e.id}>
                                                    <Card cardType='Heading' img={e.image1} title={e.name} click={() => productClick(e)} />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Offer