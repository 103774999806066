import './App.css';
import Navigation from './navigation/navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { AuthorizationProvider } from './services/auth/AuthorizationContext';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const App = () => {
  useEffect(() => {
    console.log('app Component called')

  }, [])
  return (
    <main className="App">
      <AuthorizationProvider>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path='/*' element={<Navigation />}></Route>
          </Routes>
        </BrowserRouter>
      </AuthorizationProvider>
    </main>
  );
}

export default App;


