import React from 'react';

const Sidebar = ({ isOpen, onClose, data }) => {
    console.log(data)
    return (

        <div className={`fixed w-[85vw] inset-y-0 left-0 bg-white z-50 transform transition duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="flex items-center justify-between p-4">
                <h1 className="text-black text-lg font-semibold">Product categories</h1>
                <button onClick={onClose} className="text-black focus:outline-none">
                    <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M12.3 10l5.4-5.4c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-5.4 5.4-5.4-5.4c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.4 5.4-5.4 5.4c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3l5.4-5.4 5.4 5.4c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-5.4-5.4z"></path>
                    </svg>
                </button>
            </div>
            <div className="overflow-y-auto h-full">
                {/* Sidebar content */}
                <div className='px-4'>
                    <p className="font-bold text-md">
                        Offers
                    </p>
                    <div className="ml-4 pb-5">
                        {
                            data.map(e =>
                                <p className='text-sm text-[#757575]' key={e.id} >{e.name} </p>
                            )

                        }
                        {/* <p className='text-sm text-[#757575]'>Digital Entertainment </p>
                        <p className='text-sm text-[#757575]'>Food & Grocery </p>
                        <p className='text-sm text-[#757575]'>Health & Fitness </p>
                        <p className='text-sm text-[#757575]'>Hobbies & Learning </p>
                        <p className='text-sm text-[#757575]'>Home Improvement </p>
                        <p className='text-sm text-[#757575]'>Top Brands</p>
                        <p className='text-sm text-[#757575]'>Assorted </p>
                        <p className='text-sm text-[#757575]'>Digital Entertainment </p>
                        <p className='text-sm text-[#757575]'>Food & Grocery </p>
                        <p className='text-sm text-[#757575]'>Health & Fitness </p>
                        <p className='text-sm text-[#757575]'>Hobbies & Learning </p>
                        <p className='text-sm text-[#757575]'>Home Improvement </p>
                        <p className='text-sm text-[#757575]'>Top BrandsAssorted </p>
                        <p className='text-sm text-[#757575]'>Digital Entertainment </p>
                        <p className='text-sm text-[#757575]'>Food & Grocery </p>
                        <p className='text-sm text-[#757575]'>Health & Fitness </p>
                        <p className='text-sm text-[#757575]'>Hobbies & Learning </p>
                        <p className='text-sm text-[#757575]'>Home Improvement </p>
                        <p className='text-sm text-[#757575]'>Top BrandsAssorted </p>
                        <p className='text-sm text-[#757575]'>Digital Entertainment </p>
                        <p className='text-sm text-[#757575]'>Food & Grocery </p>
                        <p className='text-sm text-[#757575]'>Health & Fitness </p>
                        <p className='text-sm text-[#757575]'>Hobbies & Learning </p>
                        <p className='text-sm text-[#757575]'>Home Improvement </p>
                        <p className='text-sm text-[#757575]'>Top Brands</p>
                        <p className='text-sm text-[#757575]'>Assorted </p>
                        <p className='text-sm text-[#757575]'>Digital Entertainment </p>
                        <p className='text-sm text-[#757575]'>Food & Grocery </p>
                        <p className='text-sm text-[#757575]'>Health & Fitness </p>
                        <p className='text-sm text-[#757575]'>Hobbies & Learning </p>
                        <p className='text-sm text-[#757575]'>Home Improvement </p>
                        <p className='text-sm text-[#757575]'>Top BrandsAssorted </p>
                        <p className='text-sm text-[#757575]'>Digital Entertainment </p>
                        <p className='text-sm text-[#757575]'>Food & Grocery </p>
                        <p className='text-sm text-[#757575]'>Health & Fitness </p>
                        <p className='text-sm text-[#757575]'>Hobbies & Learning </p>
                        <p className='text-sm text-[#757575]'>Home Improvement </p> */}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;