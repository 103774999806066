import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'users',
    initialState: [],
    reducers: {

        addUser(state, action) {
            console.log(action.payload)
            state.push(action.payload)
        },

        removeUser(state, action) {
            console.log(action.payload)
            state.splice(action.payload, 1);
        },

        deleteAllUser(state, action) {
            state.length = 0;
        }
    }

})

export default userSlice.reducer;
export const { addUser, removeUser, deleteAllUser } = userSlice.actions;
