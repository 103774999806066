
// development
// const baseUrl = 'https://api.carlsberg.bigcityvoucher.co.in'
// production
//   const baseUrl = "https://appapi.carlsberguat.bigcitydays.in"





//development uat
// export const baseUrl = "http://127.0.0.1:3000/v1"
export const baseUrl = "http://bizprime.n.api.bigcityvoucher.co.in:6008/v1"
export const ApiConfiguration = {
    dashboard: baseUrl + '/customer/dashboard',
    offerDetail: baseUrl + '/product/product-detail',
    getProductByCat: baseUrl + '/product/product_by_categoriy',
    categories: baseUrl + '/product/categories',
    register: baseUrl + '/customer/register',
    login: baseUrl + '/customer/login',
    otp: baseUrl + '/customer/verify-otp',
    banner: baseUrl + '/customer/banner',
    claimOffer: baseUrl + '/orders/claim-uniquecode',
    getMembershipDetail: baseUrl + '/customer/get-membership-detail',
    claimMembership: baseUrl + "/customer/claim-memebership",
    checkValidMember: baseUrl + "/customer/check-valid-membership",
    orderHistory: baseUrl + "/orders/order-history",
    customerDetail: baseUrl + "/customer/cutomer-detail",
    editUserDetail: baseUrl + "/customer/update-customer-detail",


}