import React from 'react'


const Card = ({ cardType, img, title, click }) => {


    return (
        <>
            {
                cardType == 'insideHeading' ?
                    <div className='rounded-sm relative' onClick={click}>
                        <img src={img} alt="" className='w-full' />
                        <div className='absolute bottom-0 left-0 right-0 bg-[#0000008a] px-2 py-2 w-100 text-white' >
                            <p className='mb-0 text-center'>
                                {title}
                            </p>
                        </div>
                    </div>
                    :
                    <div className='rounded-2xl' onClick={click}>
                        <img src={img} alt="" className='w-100' />
                        {
                            title &&
                            <div className='px-2 py-2 w-100 text-black' >
                                <p className='mb-0 text-center'>
                                    {title}
                                </p>
                            </div>
                        }
                    </div>
            }
        </>
    )
}

export default Card