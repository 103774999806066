import { IMAGES } from "../../../assets/images"
import Footer from "../footer/footer"
import Header from "../header/header"

const ContectUs = () => {
    return (
        <>
            <div className="bg-[#f1f1f1] ">
                <Header />
                <div className="w-[80%] m-auto ">
                    <div className="py-5 ">
                        <h3 className="text-center">
                            Contact Us
                        </h3>
                        <p className="text-center mb-0">
                            Any question or remarks? Just write us a message!
                        </p>
                    </div>
                    <div className="grid lg:grid-cols-5 sm:grid-cols-1 bg-white p-3 rounded mb-5">
                        <div className="lg:col-span-2 sm:w-[100%] bg-blue-600 rounded p-4">
                            <div className="mb-5">
                                <h4 className="text-white mb-0 text-xl font-bold">
                                    Contact Information
                                </h4>
                                <p className="text-[#C9C9C9] text-sm mb-0">
                                    Say something to start a live chat!
                                </p>
                            </div>
                            <div className="flex mb-5 text-sm">
                                <img src={IMAGES.phoneCall} alt="" className="mr-3" />
                                <span className="text-white">
                                    +91 9934321232
                                </span>
                            </div>

                            <div className="flex mb-5 text-sm">
                                <img src={IMAGES.mail} alt="" className="mr-3" />
                                <span className="text-white">
                                    feedback@bigcity.in.Z
                                </span>
                            </div>
                            <div className="flex mb-5 text-sm">
                                <img src={IMAGES.locaiton} alt="" className="mr-3" />
                                <span className="text-white">
                                    BigCity Promotions Mitra Towers, 10/4, 4th Floor Kasturba Road, Bangalore 560001
                                </span>
                            </div>
                            <div className="flex">
                                <div className="bg-white h-[30px] w-[30px] p-[8px] rounded-[50%] mx-2">
                                    <img src={IMAGES.insta} alt="" />
                                </div>
                                <div className="bg-white h-[30px] w-[30px] p-[8px] rounded-[50%] mx-2">
                                    <img src={IMAGES.twit} alt="" />
                                </div>
                                <div className="bg-white h-[30px] w-[30px] p-[8px] rounded-[50%] mx-2">
                                    <img src={IMAGES.game} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3 p-5">
                            <div className="flex flex-wrap mb-3" >
                                <div className="lg:w-[50%] sm:w-[100%] p-2">
                                    <label htmlFor="" className="block text-sm">
                                        First Name
                                    </label>
                                    <input type="text" className="w-[100%] border-b border-[#000]" />
                                </div>
                                <div className="lg:w-[50%] sm:w-[100%] p-2">
                                    <label htmlFor="" className="block text-sm">
                                        Last Name
                                    </label>
                                    <input type="text" className="w-[100%] border-b border-[#000]" />
                                </div>
                                <div className="lg:w-[50%] sm:w-[100%] p-2">
                                    <label htmlFor="" className="block text-sm">
                                        Email
                                    </label>
                                    <input type="text" className="w-[100%] border-b border-[#000]" />
                                </div>
                                <div className="lg:w-[50%] sm:w-[100%] p-2">
                                    <label htmlFor="" className="block text-sm">
                                        Phone Number
                                    </label>
                                    <input type="text" className="w-[100%] border-b border-[#000]" />
                                </div>
                            </div>
                            <div className="mb-3">
                                <p className="text-md block font-bold">
                                    Select Subject?
                                </p>
                                <div className="flex flex-wrap">

                                    <div className="lg:w-[25%] sm:w-[100%]">
                                        <input type="checkbox" className="rounded-[50%] mr-2" />
                                        <label htmlFor="" className="text-sm">General Inquiry</label>
                                    </div>
                                    <div className="lg:w-[25%] sm:w-[100%]">

                                        <input type="checkbox" className="rounded-[50%] mr-2" />
                                        <label htmlFor="" className="text-sm">General Inquiry</label>
                                    </div>
                                    <div className="lg:w-[25%] sm:w-[100%]">

                                        <input type="checkbox" className="rounded-[50%] mr-2" />
                                        <label htmlFor="" className="text-sm">General Inquiry</label>
                                    </div>
                                    <div className="lg:w-[25%] sm:w-[100%]">
                                        <input type="checkbox" className="rounded-[50%] mr-2" />
                                        <label htmlFor="" className="text-sm">General Inquiry</label>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="" className="block text-sm mb-1">Message</label>
                                <input type="text" placeholder="Write your message.." className="w-[100%] border-b border-black" />
                            </div>
                            <div className="lg:text-right text-center">
                                <button className="bg-blue-600 py-2 px-3 rounded text-white">
                                    Send Message
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ContectUs