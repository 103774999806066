import { useEffect, useState } from "react"
import { IMAGES } from "../../../assets/images"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApiService from "../../services/http/api-service";
import { ApiConfiguration } from "../../services/http/api-configuration";
import Toast from "../../components/toast/toast";
import { AuthorizationContext } from "../../services/auth/AuthorizationContext";

const AddMembership = (props) => {
    const [isSignUp, seIsSignUp] = useState(true)
    const [userId, setUserId] = useState('')
    const [apiError, setApiError] = useState([])
    const [membershipDetial, setMembershipDetail] = useState({})



    const validationSchema = () => {
        if (isSignUp) {
            return Yup.object().shape({
                coupon: Yup.string().required('Coupon code is required'),
                tearms: Yup.bool().oneOf([true], 'Accept Terms is required'),
            });
        } else {
            return Yup.object().shape({
                tearms: Yup.bool().oneOf([true], 'Accept Terms is required'),
            });
        }
    }


    useEffect(() => {
        getDetail()
    }, [])


    const getDetail = () => {
        ApiService.get(ApiConfiguration.getMembershipDetail).then(res => {
            console.log(res)
            setMembershipDetail(res.data.data)
        }).catch(err => {
        })
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            terms: false,
        },
        validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (data) => {
            console.log(JSON.stringify(data, null, 2));
            let payload = {
                "membership_id": membershipDetial.membership_detail.id,
                "coupon_code": data.coupon
            }
            console.log(payload)
            ApiService.post(ApiConfiguration.claimMembership, payload).then(res => {
                console.log(res)
                props.close()
            }).catch(err => {
                let error = [{
                    message: err.response.data.data.message
                }]

                console.log(error)
                setApiError(error)

            })
            // const url = isSignUp ? ApiConfiguration.register : ApiConfiguration.login
            // ApiService.post(url, payload).then((res) => {
            //     console.log(res)
            //     setUserId(res.data.data.customer_id)

            // }).catch(err => {
            //     console.log(err.response.data.error)
            //     setApiError(err.response.data.error)
            // })
        },
    });


    const closeModel = () => {
        setApiError([])
    }


    return (
        <>
            {
                apiError.length != 0 &&
                <Toast data={apiError} click={closeModel} />
            }

            {
                membershipDetial.user_detail &&
                <div className="w-full m-auto bg-transparent">
                    <div className="bg-[#ffffff] lg:w-[70vw] lg:h-[70vh] sm:w-[90vw] sm:h-[80vh] m-auto top-0 bottom-0 left-0 right-0 fixed rounded p-4 shadow shadow-sm overflow-hidden overflow-y-scroll">
                        <div className="text-right mb-3">
                            <button onClick={props.close}>
                                <img src={IMAGES.closeIcon} alt="" />
                            </button>
                            {/* <div className="w-[5%]">
                        </div> */}
                        </div>

                        <div className="container mx-auto sm:container">
                            <div className="mb-5">
                                <h6 className="text-center text-[20px] font-bold">
                                    Billing Detail
                                </h6>
                            </div>

                            <div className="grid lg:grid-cols-5 sm:grid-cols-1 gap-2">
                                <div className="lg:col-span-3">

                                    <form >
                                        <div>
                                            <div className="border-b border-[#D9D9D9] mb-3">
                                                <input type="text" className="w-[100%] h-[35px] px-2" placeholder="Name *" value={membershipDetial.user_detail.name} disabled />
                                            </div>



                                            <div className="border-b border-[#D9D9D9] mb-4">
                                                <input type="text" className="w-[100%] h-[35px] px-2" placeholder="Mobile Number*"
                                                    value={membershipDetial.user_detail.mobile} disabled
                                                />
                                            </div>

                                            <div className="border-b border-[#D9D9D9] mb-4">
                                                <input type="text" className="w-[100%] h-[35px] px-2" placeholder="Email Id*" name="email_id"
                                                    value={membershipDetial.user_detail.email_id} disabled />
                                            </div>
                                        </div>
                                    </form>
                                    <div>
                                        <h6 className="font-bold">
                                            My Cart ( 1 )
                                        </h6>
                                        <div className="grid grid-cols-8  gap-2 border border-gray-300 p-2 mb-3">
                                            <div className="lg:col-span-2">
                                                <img src={membershipDetial.membership_detail.image} alt="" />
                                            </div>
                                            <div className="lg:col-span-5 ">
                                                <h6 className="font-bold mb-1">
                                                    {membershipDetial.membership_detail.name}
                                                </h6>
                                                <p className="text-sm">
                                                    {membershipDetial.membership_detail.description}</p>
                                            </div>
                                            <div className="lg:col-span-1">
                                                <h6 className="font-bold">

                                                    ₹ {membershipDetial.membership_detail.prize}
                                                </h6>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="lg:col-span-2">
                                    <div className="border border-gray-300 px-2 py-2">
                                        <form onSubmit={formik.handleSubmit}>
                                            <div>
                                                <h6 className="font-bold">
                                                    Price Detail
                                                </h6>
                                                <div className="border-b border-[#D9D9D9] mb-3">
                                                    <input type="text" className="w-[100%] h-[35px] px-2" placeholder="Coupoun Code *"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.coupon}
                                                        name="coupon" />

                                                    <div className="text-red-500 text-sm">
                                                        {formik.errors.coupon && formik.touched.coupon
                                                            ? formik.errors.coupon
                                                            : null}
                                                    </div>
                                                </div>
                                                <div>

                                                    <div className="grid grid-cols-2 border-bottom border-black-500 px-3">
                                                        <div className="col-span-1">
                                                            <p className="text-sm font-bold">
                                                                Product
                                                            </p>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <p className="text-sm font-bold text-right">
                                                                Price
                                                            </p>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <p className="text-sm font-bold">
                                                                {membershipDetial.membership_detail.name}
                                                            </p>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <p className="text-sm font-bold text-right">
                                                                ₹ {membershipDetial.membership_detail.prize}
                                                            </p>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <p className="text-sm font-bold mb-1">
                                                                Sub Total
                                                            </p>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <p className="text-sm font-bold mb-1 text-right">
                                                                ₹ {membershipDetial.membership_detail.prize}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-2  mt-3 px-3">
                                                        <div className="col-span-1">
                                                            <p className="text-sm font-bold">
                                                                Total
                                                            </p>
                                                        </div>
                                                        <div className="col-span-1">
                                                            <p className="text-sm font-bold text-right">
                                                                ₹ {membershipDetial.membership_detail.prize}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center flex">
                                                    <input type="checkbox" className="mr-3"
                                                        name="tearms"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.tearms} />
                                                    <label htmlFor="" className="text-[#858585D9] text-[12px]">By proceeding, I agree to the & T&Cs.WebsiteOffers</label>
                                                </div>
                                                <div className="text-red-500 text-sm">
                                                    {formik.errors.tearms && formik.touched.tearms
                                                        ? formik.errors.tearms
                                                        : null}
                                                </div>

                                                <div className="mt-3">
                                                    <button type="submit" className="bg-blue-500 w-[100%] h-[40px] text-white rounded-md">
                                                        Submit
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>




                    </div>
                </div>
            }
        </>
    )
}
export default AddMembership