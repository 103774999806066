import React from 'react'
import { IMAGES } from '../../../assets/images'
import { useNavigate } from 'react-router-dom'
import { routerPath } from '../../navigation/path'

const Footer = () => {
    const navigate = useNavigate()
    const navigatePage = (value) => {
        navigate(value)
    }
    return (
        <div className='bg-[#0A142F] px-10 '>
            <div className='w-[75vw] m-auto'>

                <div className="grid lg:grid-cols-4 sm:grid-cols-1 gap-5 py-5 border-b border-[#c1c1c1]">
                    <div className="col-span-1">
                        <img src={IMAGES.bigcityLogo} alt="" />
                        <p className='text-gray-300 text-md mt-2 mb-1'>
                            +1 (7635) 547-12-97
                        </p>
                        <p className='text-gray-300 text-sm mb-0'>
                            support@BigCity.in
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p className='text-white text-2xl font-bold'>
                            Quick Links
                        </p>
                        <p className='text-gray-300 text-md' onClick={() => navigatePage(routerPath.faqs)}>
                            FAQ
                        </p>
                        <p className='text-gray-300 text-md mb-0' onClick={() => navigatePage(routerPath.contectUs)}>
                            Contact Us
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p className='text-white text-2xl font-bold invisible'>
                            Quick Links
                        </p>
                        <p className='text-gray-300 text-md' onClick={() => navigatePage(routerPath.about)}>
                            About Us
                        </p>
                        <p className='text-gray-300 text-md mb-0' onClick={() => navigatePage(routerPath.home)}>
                            Home
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p className='text-white text-2xl font-bold'>
                            Subscribe
                        </p>

                    </div>
                </div>
                <div className="grid lg:grid-cols-3 sm:grid-cols-1 container py-10">
                    <div className='text-white col-span-1 lg:text-right sm:text-lfet flex'>

                        <img src={IMAGES.linkedin} alt="" className='mx-2' />
                        <img src={IMAGES.twitter} alt="" className='mx-2' />
                        <img src={IMAGES.facebook} alt="" className='mx-2' />
                    </div>
                    <div className='text-white col-span-1 lg:text-right sm:text-lfet'>
                        <p className='flex justify-center self-center'>

                            A product of
                            <img src={IMAGES.bigcityLogo} alt="" className='ml-3 w-[15%]' />
                        </p>

                    </div>
                    <div className='text-white col-span-1 sm:text-left'>
                        <span >
                            ©2021 BigCity Promotions. All rights reserved
                        </span>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Footer