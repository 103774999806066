import heroImg from "./picture/hero-img.svg";
import liftStyleImg from './picture/shopping-lifestyle.svg';
import juniors from './picture/juniors.svg'
import eCommerceEntertainment from './picture/E-Commerce-entertainment.svg'
import foodBeverages from './picture/Food-Beverages.svg'
import groomingPersonalCare from './picture/Grooming-Personal-care.svg'
import pocketFM from './picture/Pocket-FM.svg'
import behrouz from './picture/Behrouz.svg'
import zee5 from './picture/Zee5.svg'
import theManCompany from './picture/The-Man-Company.svg'
import privilleges1 from './picture/privilleges1.svg'
import privilleges2 from './picture/privilleges2.svg'
import privilleges3 from './picture/privilleges3.svg'
import theMomsCo from './picture/The-Moms-Co.svg'
import giva from './picture/Giva.png'
import ovenStory from './picture/Oven-Story.svg'
import eatSure from './picture/Eat-Sure.svg'
import discount1 from './picture/discount1.svg'
import discount2 from './picture/discount2.svg'
import discount3 from './picture/discount3.svg'
import discount4 from './picture/discount4.svg'
import bigcityLogo from './picture/bigcity-logo.svg'
import facebook from './icons/facebook.svg'
import twitter from './icons/Twitter.svg'
import linkedin from './icons/Linkedin.svg'
import search from './icons/search.svg'
import closeIcon from './icons/close.svg';
import otp from './icons/otp.svg';
import accunt from './icons/account.svg';
import congrat from './icons/congratulation.svg';
import plus from './icons/plus.svg'
import xmark from './icons/xmark.svg';
import phoneCall from './icons/phone-call.svg';
import mail from './icons/email.svg';
import location from './icons/location-filled.svg';
import insta from './icons/insta.svg';
import twit from './icons/twitter-blue.svg';
import game from './icons/game.svg';



export const IMAGES = {
    heroImg: heroImg,
    liftStyleImg: liftStyleImg,
    juniors: juniors,
    eCommerceEntertainment: eCommerceEntertainment,
    foodBeverages: foodBeverages,
    groomingPersonalCare: groomingPersonalCare,
    pocketFM: pocketFM,
    behrouz: behrouz,
    zee5: zee5,
    theManCompany: theManCompany,
    privilleges1: privilleges1,
    privilleges2: privilleges2,
    privilleges3: privilleges3,
    theMomsCo: theMomsCo,
    giva: giva,
    ovenStory: ovenStory,
    eatSure: eatSure,
    discount1: discount1,
    discount2: discount2,
    discount3: discount3,
    discount4: discount4,
    bigcityLogo: bigcityLogo,
    twitter: twitter,
    linkedin: linkedin,
    facebook: facebook,
    search: search,
    closeIcon: closeIcon,
    otp: otp,
    accunt: accunt,
    congrat: congrat,
    plus: plus,
    xmark: xmark,
    phoneCall: phoneCall,
    mail: mail,
    game: game,
    locaiton: location,
    twit: twit,
    insta: insta











};
