import { useContext, useEffect, useState } from "react"
import { IMAGES } from "../../../assets/images"
import Footer from "../footer/footer"
import Header from "../header/header"
import Card from "../../components/card/card"
import { useNavigate, useSearchParams } from "react-router-dom"
import { routerPath } from "../../navigation/path"
import SignUp from "../sign-up/sign-up";
import ApiService from "../../services/http/api-service"
import { ApiConfiguration } from "../../services/http/api-configuration"
import Toast from "../../components/toast/toast"
import AddMembership from "../add-membership/add-membership"
import { AuthorizationContext } from "../../services/auth/AuthorizationContext"


const ClaimOffer = () => {

    const [offer, setOffer] = useState({})
    const [uniqueCode, setUniqueCode] = useState({})
    const [searchParams] = useSearchParams()
    const offerId = searchParams.get('offer_id')
    const [apiError, setApiError] = useState([])
    const [localDetail, setLocalDetail] = useState({})

    const { isAuthorized, login, logout } = useContext(AuthorizationContext);
    const cardClick = () => {
        console.log('card clicked')
    }

    const getofferCode = () => {
        let payload = {
            product_id: offer.id
        }
        ApiService.post(ApiConfiguration.claimOffer, payload).then(res => {
            setUniqueCode(res.data.data)
        }).catch(err => {
            console.log(err)
            let error = []
            error.push(err.response.data.data)
            setApiError(error)
        })
    }

    const claimNow = () => {
        setLocalDetail(JSON.parse(localStorage.getItem('login_detail')))
        console.log(localDetail)
        if (isAuthorized) {
            ApiService.get(ApiConfiguration.checkValidMember).then(res => {
                // console.log(res.data.data)
                if (res.data.data) {
                    getofferCode()
                } else {
                    setMembership(true)
                }
            }).catch(err => {
                console.log(err.response.status)
                if (err.response.status == 401) {
                    logout()
                }
            })
        } else {
            setSignup(true)
        }
    }

    useEffect(() => {
        setLocalDetail(JSON.parse(localStorage.getItem('login_detail')))
        offerDetail()
    }, [])

    const offerDetail = () => {
        ApiService.get(ApiConfiguration.offerDetail + `?id=${offerId}`).then(res => {
            console.log(res.data.data)
            setOffer(res.data.data)
        }).catch(err => {
            console.log(err)

        })
    }

    const [isSignup, setSignup] = useState(false)
    const [isMembership, setMembership] = useState(false)
    const [instructionType, setInstructionType] = useState('claim')
    const topBrand = [
        {
            id: 1,
            title: 'Pocket FM',
            img: IMAGES.pocketFM,
        },
        {
            id: 2,
            title: 'Behrouz',
            img: IMAGES.behrouz,
        },
        {
            id: 3,
            title: 'Zee5',
            img: IMAGES.zee5,
        },
        {
            id: 4,
            title: 'The Man Company',
            img: IMAGES.theManCompany,
        }
    ]
    const closeSignup = () => {
        setLocalDetail(JSON.parse(localStorage.getItem('login_detail')))
        setSignup(false)
        setMembership(false)
        if (isAuthorized) {
            ApiService.get(ApiConfiguration.checkValidMember).then(res => {
                console.log(res.data.data)
                if (res.data.data.membership_id) {
                    getofferCode()
                } else {
                    setMembership(true)
                }
            }).catch(err => {

            })
        }
    }

    const closeMembership = () => {
        setMembership(false)
    }

    const closeModel = () => {
        setApiError([])
    }
    return (
        <>
            {
                isSignup && <SignUp close={closeSignup} />
            }

            {
                apiError.length != 0 &&
                <Toast data={apiError} click={closeModel} />
            }

            {
                isMembership && <AddMembership close={closeMembership} />
            }
            <div className="bg-[#f1f1f1]">
                <Header />
                <div className="lg:w-[75vw] sm:[w-94%] m-auto py-5">
                    <div className="grid lg:grid-cols-7 sm:grid-cold-1 p-3 bg-white rounded">
                        <div className="lg:col-span-2 w-[100%] lg:pr-3 mb-4 sm:mb-0">
                            <img src={offer.image1} alt="" className="w-[100%]" />
                        </div>
                        <div className="lg:col-span-3 py-3 sm:py-3">
                            <h6 className="font-bold">
                                {offer.name}
                            </h6>
                            <p className="text-sm mb-1">
                                {offer.short_description}
                            </p>
                            <p className="text-sm mb-1">
                                {offer.description}
                            </p>
                            <p className="text-sm mb-1">
                                Offer valid till {offer.validity_end_date}
                            </p>
                            {/* <p className="text-sm mb-0">
                                Categories: , AssortedTop Brands
                            </p> */}
                        </div>
                        <div className="lg:col-span-2 sm:text-right">
                            {
                                uniqueCode.unique_code ?
                                    <p className="text-md font-semibold">
                                        Unique Code : {uniqueCode?.unique_code}
                                    </p>
                                    :
                                    <button className="border border-[#0673EA] py-2 px-4 rounded text-[#0673EA]" onClick={claimNow}>
                                        CLAIM NOW!
                                    </button>
                            }
                        </div>

                    </div>
                    <div className="grid lg:grid-cols-5 grid-cols-1 my-4 bg-white rounded p-3 gap-3" >
                        <div className="sm:col-span-1 col-span-5">
                            <button className={`block  p-2   w-[100%] rounded-t-l mb-[2px] ${instructionType == 'claim' ? 'bg-[#0673EA] text-white' : 'bg-[#f3f3f3] text-black'}`} onClick={() => setInstructionType('claim')}>
                                How to Redeem
                            </button>
                            <button className={`block  p-2   w-[100%] mb-[2px] ${instructionType == 'tearms' ? 'bg-[#0673EA] text-white' : 'bg-[#f3f3f3] text-black'}`} onClick={() => setInstructionType('tearms')}>
                                Terms & Conditions
                            </button>
                        </div>
                        <div className="col-span-4">
                            {
                                instructionType == 'claim' ?
                                    <div className="text-bold">
                                        <p>
                                            {offer.how_to_redeem}
                                        </p>
                                    </div>
                                    :
                                    <div>
                                        {offer.t_n_c}
                                    </div>
                            }
                        </div>

                    </div>
                    <div className="grid lg:grid-cols-4 sm:grid-cols-1">
                        {
                            topBrand.map((e, i) =>
                                <div className="px-2 overflow-hidden" key={i}>
                                    <Card cardType='Heading' img={e.img} title={e.title} click={cardClick} />
                                </div>
                            )
                        }
                    </div>
                </div>
                <Footer />

            </div>
        </>
    )
}

export default ClaimOffer

