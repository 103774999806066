import { useState, useContext } from "react"
import { IMAGES } from "../../../assets/images"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApiService from "../../services/http/api-service";
import { ApiConfiguration } from "../../services/http/api-configuration";
import Toast from "../../components/toast/toast";

import { AuthorizationContext } from "../../services/auth/AuthorizationContext"

const SignUp = (props) => {
    const [isSignUp, seIsSignUp] = useState(true)
    const [userId, setUserId] = useState('')
    const [apiError, setApiError] = useState([])
    const { isAuthorized, login, logout } = useContext(AuthorizationContext);
    const [signUpStep, setSignUpStep] = useState(
        {
            step_1: true,
            step_2: false,
            step_3: false,
        }
    )
    const firstStep = () => {
        setSignUpStep(
            {
                step_1: true,
                step_2: true,
                step_3: false,
            }
        )
    }
    const secondStep = () => {
        setSignUpStep(
            {
                step_1: true,
                step_2: true,
                step_3: true,
            }
        )
    }
    const completeStep = () => {

    }

    const tabChanged = (value) => {
        formik.handleReset()
        value == 'signin' ? seIsSignUp(true) : seIsSignUp(false)
    }

    const validationSchema = () => {
        if (isSignUp) {
            return Yup.object().shape({
                name: Yup.string().required('Fullname is required'),
                mobile_no: Yup.string()
                    .required('mobile number is required')
                    .min(10, 'mobile number must be at least 10 characters')
                    .max(100, 'mobile number must not exceed 10 characters'),
                email_id: Yup.string().required('Email is required').email('Email is invalid'),
                // card_detail: Yup.string()
                //     .required('card detail is required'),
                tearms: Yup.bool().oneOf([true], 'Accept Terms is required'),
            });
        } else {
            return Yup.object().shape({

                mobile_no: Yup.string()
                    .required('mobile number is required')
                    .min(10, 'mobile number must be at least 10 characters')
                    .max(100, 'mobile number must not exceed 10 characters'),
                // card_detail: Yup.string()
                //     .required('card detail is required'),
                tearms: Yup.bool().oneOf([true], 'Accept Terms is required'),
            });
        }

    }

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile_no: '',
            email_id: '',
            // card_detail: '',
            terms: false,
        },
        validationSchema,
        // validateOnChange: false,
        // validateOnBlur: false,
        onSubmit: (data) => {
            console.log(JSON.stringify(data, null, 2));
            let payload = {
                name: data.name,
                email_id: data.email_id,
                mobile: data.mobile_no,
                // card_number: data.card_detail
            }
            const url = isSignUp ? ApiConfiguration.register : ApiConfiguration.login
            ApiService.post(url, payload).then((res) => {
                console.log(res)
                setUserId(res.data.data.customer_id)
                firstStep()
            }).catch(err => {
                console.log(err)
                if (err.response.data.error) {
                    setApiError(err.response.data.error)
                } else {
                    setApiError(err.response.data.data)

                }
            })
        },
    });

    const validateOtpSchama = () => {
        return Yup.object().shape({
            otp: Yup.string()
                .required('otp is required')
                .min(6, 'otp must be at least 6 characters')
                .max(6, 'otp must not exceed 6 characters')
        })
    }

    const otpFormik = useFormik({
        initialValues: {
            otp: ''
        },
        validateOtpSchama,
        onSubmit: (data) => {
            console.log(JSON.stringify(data, null, 2));
            let payload = {
                otp: data.otp,
                customer_id: userId,
            }
            ApiService.post(ApiConfiguration.otp, payload).then((res) => {
                console.log(res)
                localStorage.setItem('login_detail', JSON.stringify(res.data.data))
                localStorage.setItem('auth_token', res.data.data.token)
                login()
                secondStep()
            }).catch(err => {
                console.log(err)
                setApiError(err.response.data.data)
            })
        },
    });

    const closeModel = () => {
        setApiError([])
    }


    return (
        <>
            {
                apiError?.length != 0 &&
                <Toast data={apiError} click={closeModel} />
            }

            <div className="w-full m-auto bg-transparent">
                <div className="bg-[#ffffff] lg:w-[50vw] lg:h-[80vh] sm:w-[90vw] sm:h-[80vh] m-auto top-0 bottom-0 left-0 right-0 fixed rounded p-4 shadow shadow-sm overflow-hidden overflow-y-scroll">
                    <div className="flex">
                        <div className="w-[95%] flex">
                            <h6 className={`font-bold mr-3 cursor-pointer  py-2 px-3  ${isSignUp && 'bg-blue-500 rounded-full text-white'}`} onClick={() => tabChanged('signin')}>
                                SIGN UP
                            </h6>
                            <h6 className={`font-bold cursor-pointer py-2 px-3 ${!isSignUp && 'bg-blue-500 rounded-full text-white'}`} onClick={() => tabChanged('signup')}>
                                SIGN IN
                            </h6>

                        </div>
                        <div className="w-[5%]">
                            <button onClick={props.close}>
                                <img src={IMAGES.closeIcon} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className="flex mt-3">
                        <div className="w-[33%]">
                            <div className="relative ">
                                <div className={`h-[50px] w-[50px] rounded-full flex justify-center align-middle z-10 m-auto ${signUpStep.step_1 ? 'bg-gradient-to-r from-cyan-500 to-blue-500' : 'bg-[#D9D9D9]'}`}>
                                    <img src={IMAGES.accunt} alt="" className="m-auto" />
                                </div>
                                <div className={`h-[2px] w-full absolute top-[50%] z-[-1] ${signUpStep.step_1 ? 'bg-blue-500' : 'bg-[#D9D9D9]'}`} ></div>
                            </div>
                            <p className="text-[10px] text-center mt-2">
                                Account
                            </p>

                        </div>
                        <div className="w-[33%]">
                            <div className="relative">
                                <div className={`h-[50px] w-[50px] rounded-full flex justify-center align-middle z-10 m-auto ${signUpStep.step_2 ? 'bg-gradient-to-r from-cyan-500 to-blue-500' : 'bg-[#D9D9D9]'}`}>
                                    <img src={IMAGES.otp} alt="" className="m-auto" />
                                </div>
                                <div className={`h-[2px] w-full absolute top-[50%] z-[-1] ${signUpStep.step_2 ? 'bg-blue-500' : 'bg-[#D9D9D9]'}`} ></div>
                            </div>
                            <p className="text-[10px] text-center mt-2">
                                OTP
                            </p>

                        </div>
                        <div className="w-[33%]">
                            <div className="relative ">
                                <div className={`h-[50px] w-[50px] rounded-full flex justify-center align-middle z-10 m-auto ${signUpStep.step_3 ? 'bg-gradient-to-r from-cyan-500 to-blue-500' : 'bg-[#D9D9D9]'}`}>
                                    <img src={IMAGES.congrat} alt="" className="m-auto" />
                                </div>
                                <div className={`h-[2px] w-full absolute top-[50%] z-[-1] ${signUpStep.step_3 ? 'bg-blue-500' : 'bg-[#D9D9D9]'}`} ></div>

                            </div>
                            <p className="text-[10px] text-center mt-2">
                                CONGRATULATIONS
                            </p>
                        </div>
                    </div>
                    {
                        signUpStep.step_1 && !signUpStep.step_2 ?
                            <div className="w-[80%] m-auto py-3">
                                <h6 className="text-center text-[20px] font-bold">
                                    Account Information
                                </h6>
                                <form onSubmit={formik.handleSubmit}>
                                    <div>
                                        {
                                            isSignUp &&
                                            <div className="border-b border-[#D9D9D9] mb-3">
                                                <input type="text" className="w-[100%] h-[35px] px-2" placeholder="Name *"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.name}
                                                    name="name" />

                                                <div className="text-red-500 text-sm">
                                                    {formik.errors.name && formik.touched.name
                                                        ? formik.errors.name
                                                        : null}
                                                </div>
                                            </div>

                                        }
                                        <div className="border-b border-[#D9D9D9] mb-4">
                                            <input type="text" className="w-[100%] h-[35px] px-2" placeholder="Mobile Number*"
                                                name="mobile_no"
                                                onChange={formik.handleChange}
                                                value={formik.values.mobile_no}
                                            />
                                            <div className="text-red-500 text-sm">
                                                {formik.errors.mobile_no && formik.touched.mobile_no
                                                    ? formik.errors.mobile_no
                                                    : null}
                                            </div>
                                        </div>
                                        {
                                            isSignUp &&
                                            <div className="border-b border-[#D9D9D9] mb-4">
                                                <input type="text" className="w-[100%] h-[35px] px-2" placeholder="Email Id*" name="email_id"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email_id} />

                                                <div className="text-red-500 text-sm">
                                                    {formik.errors.email_id && formik.touched.email_id
                                                        ? formik.errors.email_id
                                                        : null}
                                                </div>
                                            </div>
                                        }
                                        {/* <div className="border-b border-[#D9D9D9] mb-4">
                                            <input type="text" className="w-[100%] h-[35px] px-2" placeholder="Enter first 6-digits of StanChart Debit/Credit Card *"
                                                name="card_detail"
                                                onChange={formik.handleChange}
                                                value={formik.values.card_detail} />
                                            <div className="text-red-500 text-sm">
                                                {formik.errors.card_detail && formik.touched.card_detail
                                                    ? formik.errors.card_detail
                                                    : null}
                                            </div>
                                        </div> */}
                                        <div className="text-center flex">
                                            <input type="checkbox" className="mr-3"
                                                name="tearms"
                                                onChange={formik.handleChange}
                                                value={formik.values.tearms} />
                                            <label htmlFor="" className="text-[#858585D9] text-[12px]">By proceeding, I agree to the & T&Cs.WebsiteOffers</label>
                                        </div>
                                        <div className="text-red-500 text-sm">
                                            {formik.errors.tearms && formik.touched.tearms
                                                ? formik.errors.tearms
                                                : null}
                                        </div>
                                        <div className="text-center mt-5">
                                            <button className="bg-[#086BDA] text-white w-[150px] h-[35px] rounded" type="submit">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            : signUpStep.step_2 && !signUpStep.step_3 ?
                                <div className="w-[80%] m-auto py-3">
                                    <h6 className="text-center text-[20px] font-bold">
                                        Enter OTP
                                    </h6>
                                    <div>
                                        <form action="" onSubmit={otpFormik.handleSubmit}>
                                            <div className="border-b border-[#D9D9D9] mb-3">
                                                <input type="text" className="w-[100%] h-[35px]" placeholder="OTP *" name="otp"
                                                    onChange={otpFormik.handleChange}
                                                    value={otpFormik.values.otp} />
                                                <div className="text-red-500 text-sm">
                                                    {otpFormik.errors.otp && otpFormik.touched.otp
                                                        ? otpFormik.errors.otp
                                                        : null}
                                                </div>
                                            </div>


                                            <div className="text-center mt-5">
                                                <button className="bg-[#086BDA] text-white w-[150px] h-[35px] rounded" type="submit">
                                                    Next
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                :
                                <div className="w-[80%] m-auto py-3">
                                    <h6 className="text-center text-[20px] font-bold">
                                        Congratulations
                                    </h6>
                                    <div>
                                        <p className="text-center text-[16px]">
                                            You have successfully registered! <br />
                                            You will be able to benefit from our web site, <br />
                                            Lorem Ipsum has been the industry's standard dummy
                                        </p>
                                        <div className="text-center mt-5">
                                            <button className="bg-[#086BDA] text-white w-[150px] h-[35px] rounded" onClick={props.close}>
                                                OK
                                            </button>
                                        </div>
                                    </div>
                                </div>

                    }

                </div>
            </div>
        </>
    )
}
export default SignUp