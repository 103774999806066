import { Routes, Route } from 'react-router-dom';
import { routerPath } from './path';
import Home from '../pages/home/home';
import About from '../pages/about';
import Offer from '../pages/offer/offer';
import ClaimOffer from '../pages/claim-offer/claim-offer';
import Faqs from '../pages/faqs/faqs';
import ContectUs from '../pages/contect-us/contect-us';
import OrderHistory from '../pages/order-history';
import Profile from '../pages/profile';



const OnboardRoutes = () => {
    return (
        <Routes>
            <Route path='*' element={<Home />}></Route>
            <Route path='/' element={<Home />}></Route>
            <Route path={routerPath.home} element={<Home />}></Route>
            <Route path={routerPath.about} element={<About />}></Route>
            <Route path={routerPath.offer} element={<Offer />}></Route>
            <Route path={routerPath.claimOffer} element={<ClaimOffer />}></Route>
            <Route path={routerPath.faqs} element={<Faqs />}></Route>
            <Route path={routerPath.contectUs} element={<ContectUs />}></Route>
            <Route path={routerPath.OrderHistory} element={<OrderHistory />}></Route>
            <Route path={routerPath.profile} element={<Profile />}></Route>


        </Routes>
    )
}


export default OnboardRoutes;