import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../header/header";
import Card from "../../components/card/card";
import { IMAGES } from "../../../assets/images";
import Footer from "../footer/footer";
import { routerPath } from "../../navigation/path";
import { ApiConfiguration } from "../../services/http/api-configuration";
import ApiService from "../../services/http/api-service"

const Home = () => {
    const [dashboardData, setDashboardData] = useState({})
    const [bannerData, setBannerData] = useState({})
    const navigate = useNavigate()
    const cardClick = (data) => {
        console.log("card clicked")
        navigate(routerPath.offer, { data: data })
    }


    useEffect(() => {
        getDashboardData()
        getBannerData()
    }, [])

    const getDashboardData = () => {
        console.log("Dads")
        ApiService.get(ApiConfiguration.dashboard).then(res => {
            console.log(res.data.data)
            setDashboardData(res.data.data)
            localStorage.setItem('is_user_login', res.data.data.is_user_login)
        }).catch(err => {

        })
    }

    const getBannerData = () => {
        console.log("Dads")
        ApiService.get(ApiConfiguration.banner + `?page=dashboard`).then(res => {
            console.log(res.data.data)
            setBannerData(res.data.data[0])
        }).catch(err => {

        })
    }

    const offerClick = (data) => {
        console.log(data)
        navigate(`${routerPath.claimOffer}?offer_id=${data.id}`)
    }

    const categoryClick = (data) => {
        navigate(`${routerPath.offer}?category_id=${data.id}`)

    }



    const handpickedOffers = [
        {
            id: 1,
            title: 'The Moms Co',
            img: IMAGES.theMomsCo,
        },
        {
            id: 2,
            title: 'Giva',
            img: IMAGES.giva,
        },
        {
            id: 3,
            title: 'Oven Story',
            img: IMAGES.ovenStory,
        },
        {
            id: 4,
            title: 'Eat Sure',
            img: IMAGES.eatSure,
        }
    ]

    const discount = [
        {
            id: 1,
            img: IMAGES.discount1,
        },
        {
            id: 2,
            img: IMAGES.discount2,
        },
        {
            id: 3,
            img: IMAGES.discount3,
        },
        {
            id: 3,
            img: IMAGES.discount4,
        },
    ]
    return (
        <>
            <div >
                <Header />
                <div className="">
                    <img src={bannerData?.image} alt="" />
                </div>
                <div className="container mx-auto sm:container">
                    <div className="grid lg:grid-cols-5 sm:grid-cols-1 py-10 gap-2">
                        {
                            dashboardData.product_categories &&
                            dashboardData.product_categories.map((e, i) =>
                                <div className="px-2 overflow-hidden" key={e.id}>
                                    <Card cardType='insideHeading' img={e.image} title={e.name} click={() => categoryClick(e)} />
                                </div>
                            )
                        }
                    </div>
                    <div>
                        <div>
                            <h4 className="mb-0 px-3 font-bold">
                                Offers from Top Brands
                            </h4>
                        </div>
                        <div className="grid container lg:grid-cols-4 sm:grid-cols-1 py-3 gap-2">
                            {
                                dashboardData.top_brand &&
                                dashboardData.top_brand.map((e, i) =>
                                    <div className="px-2 overflow-hidden" key={i}>
                                        <Card cardType='Heading' img={e.image1} title={e.name} click={() => offerClick(e)} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <h4 className="mb-0 px-3 font-bold">
                                Free Privileges for You
                            </h4>
                        </div>
                        <div className="grid container lg:grid-cols-3 sm:grid-cols-1 py-3 gap-2">
                            {
                                dashboardData.privileges &&
                                dashboardData.privileges.map((e, i) =>
                                    <div className="px-2 overflow-hidden" key={e.id}>
                                        <Card cardType='Heading' img={e.image1} click={cardClick} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <h4 className="mb-0 px-3 font-bold">
                                Handpicked Offers for You
                            </h4>
                        </div>
                        <div className="grid container lg:grid-cols-4 sm:grid-cols-1 py-3 gap-2">
                            {
                                handpickedOffers.map((e, i) =>
                                    <div className="px-2 overflow-hidden" key={i}>
                                        <Card cardType='Heading' img={e.img} title={e.title} click={offerClick} />
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div>
                        <div>
                            <h5 className="mb-0 px-3 font-bold">
                                Best Discount Across Categories
                            </h5>
                        </div>
                        <div className="grid container lg:grid-cols-4 sm:grid-cols-1 py-3 gap-2">
                            {
                                discount.map((e, i) =>
                                    <div className="px-2 overflow-hidden" key={i}>
                                        <Card cardType='Heading' img={e.img} click={cardClick} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        </>
    )
}

export default Home

