import { useEffect, useState } from "react"
import { IMAGES } from "../../../assets/images"
import Header from "../header/header"
import ApiService from "../../services/http/api-service"
import { ApiConfiguration } from "../../services/http/api-configuration"


const OrderHistory = () => {
    const [orderData, SetOrderData] = useState([])

    useEffect(() => {
        getOrderData()
    }, [])

    const getOrderData = () => {
        ApiService.get(ApiConfiguration.orderHistory).then((res) => {
            console.log(res)
            SetOrderData(res?.data?.data)
        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <div>
            <Header />
            <div className="container mt-10 ">
                {
                    orderData.map(item => (
                        <div className="md:w-[70%] mx-auto border border-light-500 sm:flex mb-3 rounded" key={item?.order_id}>
                            <div className="">
                                <img src={item?.image1} alt="" className="sm:w-[100%] md:w-[250px] m-auto" />
                            </div>
                            <div className="p-3 d-flex flex-col justify-center">
                                <p className="mb-1 font-bold text-xl">
                                    {item?.product_name}
                                </p>
                                <p className="mb-1 font-bold text-md">
                                    Offer Code : {item?.unique_code}
                                </p>
                                <p className="mb-1 text-sm">
                                    Offer Redeemed : 22-{item?.order_date}
                                </p>
                                <p className="mb-1 text-sm">
                                    Offer Valid Till : {item?.expiry_date}
                                </p>
                            </div>
                        </div>

                    ))
                }
            </div>
        </div>
    )
}

export default OrderHistory