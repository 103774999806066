import React from 'react'
import { IMAGES } from '../../../assets/images'



const Toast = ({ data, click, is_success = false }) => {

    setTimeout(() => {
        click()
    }, 6000);

    return (
        <>
            <div className='text-right fixed right-5 p-3 top-5 bg-blue-50 rounded-lg z-10' >
                <button onClick={click}>
                    <img src={IMAGES.closeIcon} alt="" />
                </button>
                {data &&
                    data.map((e, i) =>
                        <div className={`${is_success ? 'bg-green-500' : 'bg-red-500'} mt-2 rounded-md`} key={i}>
                            <p className='text-white px-4 py-1 '>
                                {e.message}
                            </p>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default Toast