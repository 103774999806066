import { useFormik } from 'formik';
import * as Yup from 'yup';
import ApiService from "../../services/http/api-service";
import { ApiConfiguration } from "../../services/http/api-configuration";
import Header from '../header/header';
import { useEffect, useState } from 'react';
import Toast from '../../components/toast/toast';

const Profile = () => {
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        mobile: Yup.string().required('Mobile is required'),
        email: Yup.string().email('Invalid email format').required('Email is required')
    });
    const [userDetail, setUserDetail] = useState({})
    const [apiError, setApiError] = useState([])

    useEffect(() => {
        getUserDetail()
    }, [])

    useEffect(() => {
        if (userDetail) {
            formik.setValues({
                name: userDetail.name || '',
                mobile: userDetail.mobile || '',
                email: userDetail.email_id || '' // Update this line if email is different in API response
            });
        }
    }, [userDetail]);

    const getUserDetail = () => {
        ApiService.get(ApiConfiguration.customerDetail).then((res) => {
            console.log(res.data.data)
            setUserDetail(res.data.data)

        }).catch(err => {

        })
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            email: ''
        },
        validationSchema,
        onSubmit: (data) => {
            console.log(JSON.stringify(data, null, 2));
            const payload = {
                name: data.name,
                mobile: data.mobile,
                email: data.email
            };
            console.log(payload);
            ApiService.post(ApiConfiguration.editUserDetail, payload)
                .then(res => {
                    console.log(res);
                    setApiError([{ message: res.data.data }])
                })
                .catch(err => {
                    const errorMessage = err?.response?.data?.data?.message || 'An error occurred';
                    console.error(errorMessage);
                });
        },
    });

    const closeModel = () => {
        setApiError([])
    }

    return (
        <div>
            {
                apiError?.length != 0 &&
                <Toast data={apiError} click={closeModel} is_success={true} />
            }
            <Header />

            <div className="bg-light-500 w-[100vw] mt-[100px] flex justify-center items-center px-3 py-4 ">
                {/* Parent div with 70% width on desktop */}
                <div className="w-[100%] md:w-[70%] flex flex-col md:flex-row justify-between items-start border border-light-800 p-3">
                    {/* First child div */}
                    <div className="w-[100%] md:w-[50%] mb-4 md:mb-0 text-center">
                        <img src={userDetail?.image} alt="" className='w-[200px] mb-3 m-auto' />
                        <p className='mb-1 font-bold'>Name : {userDetail?.membership_name}</p>
                        <p className='mb-0'>Price : {userDetail?.membership_price}</p>
                    </div>

                    {/* Second child div (form) */}
                    <div className="w-[100%] md:w-[50%]">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="border-b border-[#D9D9D9] mb-3">
                                <input
                                    type="text"
                                    className="w-[100%] h-[35px] px-2"
                                    placeholder="Name *"
                                    name="name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                                <div className="text-red-500 text-sm">
                                    {formik.errors.name && formik.touched.name ? formik.errors.name : null}
                                </div>
                            </div>

                            <div className="border-b border-[#D9D9D9] mb-3">
                                <input
                                    type="text"
                                    className="w-[100%] h-[35px] px-2"
                                    placeholder="Mobile Number *"
                                    name="mobile"
                                    onChange={formik.handleChange}
                                    value={formik.values.mobile}
                                    disabled
                                />
                                <div className="text-red-500 text-sm">
                                    {formik.errors.mobile && formik.touched.mobile ? formik.errors.mobile : null}
                                </div>
                            </div>

                            <div className="border-b border-[#D9D9D9] mb-3">
                                <input
                                    type="text"
                                    className="w-[100%] h-[35px] px-2"
                                    placeholder="Email *"
                                    name="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                />
                                <div className="text-red-500 text-sm">
                                    {formik.errors.email && formik.touched.email ? formik.errors.email : null}
                                </div>
                            </div>

                            <div>
                                <button className="w-[100%] bg-blue-600 text-white h-[45px] rounded" type="submit">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Profile;
