import { useState } from "react"
import Accordion from "../../components/accordion/accordion"
import Footer from "../footer/footer"
import Header from "../header/header"

const Faqs = () => {
    const faqs = [
        {
            id: 1,
            question: 'I HAVE RECEIVED THE VOUCHER. CAN I GIVE IT TO SOMEONE ELSE?',
            answer: 'Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.',
            is_open: true
        },
        {
            id: 2,
            question: 'HOW TO I REDEEM AN OFFER?',
            answer: 'Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.',
            is_open: false
        },
        {
            id: 3,
            question: 'HOW DO I REDEEM MY PRIVILEGES?',
            answer: 'Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.',
            is_open: false
        },
        {
            id: 4,
            question: 'WHAT IS THE VALIDITY OF MY VOUCHER?',
            answer: 'Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.',
            is_open: false
        },
        {
            id: 5,
            question: 'CAN I USE MY VOUCHERS ONLINE?',
            answer: 'Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.',
            is_open: false
        },
        {
            id: 6,
            question: 'HOW MANY TIMES CAN A VOUCHER BE USED?',
            answer: 'Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.',
            is_open: false
        },
        {
            id: 7,
            question: 'IS THERE A PHYSICAL VOUCHER?',
            answer: 'Timperdiet gravida scelerisque odio nunc. Eget felis, odio bibendum quis eget sit lorem donec diam. Volutpat sed orci turpis sit dolor est a pretium eget. Vitae turpis orci vel tellus cursus lorem vestibulum quis eu. Ut commodo, eget lorem venenatis urna.',
            is_open: false
        }
    ]
    const [faq, setFaq] = useState(faqs)

    const accordionClick = (id) => {
        console.log(id)
        faqs[id - 1].is_open = true
        console.log(faq)
        setFaq(faq)
    }
    return (
        <>
            <div>
                <Header />
                <div className="bg-[#f1f1f1]">
                    <div className="container py-5">
                        <h4 className="font-xl mb-4 font-bold">
                            Frequently Asked Questions
                        </h4>
                        <div className="bg-[#ffffff] p-3">
                            {
                                faqs.map(e =>
                                    <Accordion question={e.question} answer={e.answer} is_open={e.is_open} id={e.id} key={e.id} click={() => accordionClick(e.id)} />
                                )
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Faqs